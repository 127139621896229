import React from 'react'
import { Helmet } from "react-helmet"
import { Link, graphql } from 'gatsby'
import Seo from '../components/seo'
import NavMobile from '../components/nav_mobile'
import BackgroundImage from 'gatsby-background-image'
import GoogleReviews from '../components/google_reviews_slider'
import Copyright from '../components/copyright'
import Gallery from '../components/gallery'

export default ({ data }) => {
    const entry = data.markdownRemark
    return (
        <div className="page">
            <Helmet>
                <link href="https://fonts.googleapis.com/css2?family=Oswald&display=swap" rel="stylesheet" />
            </Helmet>
            <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
            <NavMobile />
            <div className="layout">
                <BackgroundImage fluid={entry.frontmatter.image.childImageSharp.fluid} backgroundColor={`#ffffff`}>
                    <div className="title__gradient">
                        <div className="layout__container">
                            <div className="layout__wide title center">
                                <h1>{entry.frontmatter.heading}</h1>
                                <p>Phone us for a quick response and fast quote.<br />Call us now on <a href="tel:0800828299" title="0800 8282 99" rel="nofollow noopener">0800 8282 99</a>.</p>
                            </div>
                        </div>
                    </div>
                </BackgroundImage>
            </div>
            <div className="layout grey">
                <div className="layout__container">
                    <div className="layout__wide">
                        <nav className="breadcrumb">
                            <p><Link to='/'>Home</Link> &nbsp;&rsaquo;&nbsp; <Link to={`/${entry.frontmatter.section_url}/`}>{entry.frontmatter.section}</Link> &nbsp;&rsaquo;&nbsp; <strong>{entry.frontmatter.title}</strong></p>
                        </nav>
                    </div>
                </div>
            </div>
            <Gallery images={entry.frontmatter.gallery} />
            <GoogleReviews />
            <Copyright />
        </div>
    )
}

export const query = graphql`
	query($slug: String!) {
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
                section
                section_url
                title
				page_title
                meta_description
                heading
                image {
                    childImageSharp {
                        fluid(maxWidth: 1280) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                gallery {
                    image {
                        small: childImageSharp {
                            fluid(maxWidth: 480, maxHeight: 480) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                        large: childImageSharp {
                            fluid(maxWidth: 1024) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    caption
                }
			}
			fields {
				slug
			}
			html
		}
	}
`